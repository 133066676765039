<template>
<div>
  <b-row>
    <b-col>
      <b-card>
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">Tapal Direct</h5>
        </b-card-body>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.tapal"
        class="contact-table"
        >
          <template #cell(head_title)="data">
            <span style="max-width:50%">
              {{ formatTitle(data.item.head_title) }}
            </span>
          </template>
          <template #cell(balance)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.balance) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
  <b-row class="mt-4">
    <b-col>
      <b-card>
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">Circles Data</h5>
        </b-card-body>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.circles"
        class="contact-table"
        >
          <template #cell(lockCircle)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.lockCircle) }}
            </span>
          </template>
          <template #cell(currentCircle)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.currentCircle) }}
            </span>
          </template>
          <template #cell(mdTarnol)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.mdTarnol) }}
            </span>
          </template>
          <template #cell(directInvenstment)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.directInvenstment) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</div>  
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import NProgress from "nprogress";

export default {
  name: "tapal-dashboard",
  data: () => ({
    itemsTapal: [],
    itemsCircle: [],
    dashboardData: {
      tapalTotal: 0,
      mdtarnolTapal: 0
    },
    tapal:[44,55,59,3,36,49,15,19,26,43,38,56,54],
    cutOfDate: null,
    dates: {
      start: null,
      end: null,
      codate: null
    }
  }),
  computed: {
      ...mapState({
        codate: state => state.dashboard.codate,
        tapalCircle: state => state.dashboard.tapal,
        loadedData: state => state.dashboard.loadedData,
        refreshState: state => state.dashboard.refreshState,
        userRole: state => state.auth.authData
      }),
  },
  watch: {
    refreshState(val){
      if(val){
        this.itemsTapal = [];
        this.itemsCircle = [];
        this.initDashboard();
      }
    }
  },
  async mounted(){
    if(!this.userRole){
      if(localStorage.getItem('token')){
        let roles = localStorage.getItem('access').split(',');
        roles = roles.map(r => {
          return parseInt(r);
        });
        this.saveAuthData({username: localStorage.getItem('user'), access: roles});
      }
      else{
        this.saveAuthData(null);
        this.$router.push({path: "/dashboard/tapal-dashboard"});
      }
    }
    if(!this.loadedData){
      await this.initDashboard();
    }
  },
  methods:{
    ...mapActions(["reloadData", "saveLoadedData", "saveAuthData", "getCircles", "getFauji", "getLu", "getMolfix", "getTapal", "getStockValue", "getPendingOrders", "getSupplierBalance", "getDiscounts", "getAutoDiscounts", "getShopsCredit", "getSales", "getPurchase", "getSalesmanCredit", "getLedger"]),
    formatTitle(text){
      if(text.includes(":")){
        return text.substr(0,15);
      }
      else{
        return text;
      }
    },
    getFirstDayOfMonth(dt){
      return moment(dt).startOf("month").format('YYYY-MM-DD HH:mm:ss');
    },
    getLastDayOfMonth(dt){
      return moment(dt).endOf("month").format('YYYY-MM-DD HH:mm:ss');
    },
    async initDates(){
      return new Promise((resolve) => {
        NProgress.start();
        this.getCircles().then(() => {
          this.dates.start = this.getFirstDayOfMonth(this.codate);
          this.dates.end = this.getLastDayOfMonth(this.codate);
          this.dates.codate = this.codate;
          resolve();
        })
      });
    },
    async initLedgers(){
      return new Promise((resolve) => {
        this.getLedger({id:4,ledger:14,dates:this.dates}).then(o => {
          this.dashboardData.mdtarnolTapal = o[0].debit - o[0].credit;
          resolve();
        })
      })
    },
    initCircles(){
      this.getCircles().then(() => {
        this.itemsCircle.push({
          business: "Tapal Direct",
          lockCircle: this.tapalCircle,
          currentCircle: this.dashboardData.tapalTotal,
          mdTarnol: this.dashboardData.mdtarnolTapal,
          directInvenstment: this.dashboardData.tapalTotal + this.dashboardData.mdtarnolTapal 
        });
        this.reloadData(false);
        NProgress.done();
      })
    },
    async prepareTapal(){
      return new Promise((resolve) => {
        this.getTapal({ids: this.tapal,dates:this.dates}).then(async fdata => {
          await this.timeOut(1000);
          this.getStockValue({id:4,dates:this.dates}).then(async stock => {
            this.itemsTapal.push({"head_title": "Stock Value", "balance":stock[0].stock});
            await this.timeOut(1000);
            this.getPendingOrders({id:4,dates:this.dates}).then(async p => {
              this.itemsTapal.push({"head_title": "Pending Orders", "balance":p[0].balance});
              await this.timeOut(1000);
              this.getSupplierBalance({id:4,dates:this.dates}).then(async sup => {
                await this.timeOut(1000);
                sup.forEach(async (s,n) => {
                  await this.timeOut(1000);
                  this.getLedger({id:4,ledger:s.supplier_id,dates:this.dates}).then(l => {
                    this.itemsTapal.push({"head_title": s.supplier_name, "balance":l[0].debit - l[0].credit - s.purchase});
                  })
                  if(sup.length === n+1){
                    await this.timeOut(1000);
                    this.getShopsCredit({id:4,dates:this.dates}).then(async shop => {
                      this.itemsTapal.push({"head_title": "Shops Credit", "balance":shop[0].shopcredit});
                      await this.timeOut(1000);
                      this.getSales({id:4,dates:this.dates}).then(async sale => {
                        await this.timeOut(1000);
                        this.getPurchase({id:4,dates:this.dates}).then(async purchase => {
                        await this.timeOut(1000);
                        this.getSalesmanCredit({id:4,dates:this.dates}).then(async credit => {
                          this.itemsTapal.push({"head_title": "Salesman Credit", "balance":(sale[0].sales + credit[0].debit) - credit[0].credit});
                          await this.timeOut(1000);
                          this.getDiscounts({id:4,dates:this.dates}).then(async disc => {
                            await this.timeOut(1000);
                            this.getLedger({id:4, type:"discounts", ledger:[5,6,7,8,9,10,11,13], dates:this.dates}).then(async dl => {
                            this.itemsTapal.push({"head_title": "Discounts", "balance":(disc[0].discounts + dl[0].debit) - dl[0].credit});
                            await this.timeOut(1000);
                            this.getLedger({id:4, ledger:[12], dates:this.dates}).then(dll => {
                            this.itemsTapal.push({"head_title": dll[0].head_title, "balance":dll[0].debit - (purchase[0].tradedisc + dll[0].credit)});
                            fdata.forEach((el, n) => {
                              this.itemsTapal.push(el);
                              if(fdata.length === n+1){
                                this.dashboardData.tapalTotal = _.sumBy(this.itemsTapal, function(o) { return o.balance; });
                                this.itemsTapal.push({"head_title": "Total", "balance": this.dashboardData.tapalTotal});
                                this.itemsTapal.push({"head_title": "Circle", "balance": this.tapalCircle});
                                this.itemsTapal.push({"head_title": "Diff", "balance": this.dashboardData.tapalTotal - this.tapalCircle});
                                resolve();
                              }
                            });
                            });
                            });
                          });
                        });
                        });
                      });
                    });
                  }
                })
              });
            });
          });
        });
      })
    },
    async timeOut(interval){
      return new Promise((resolve) => {
        setTimeout(() => {  resolve(); }, interval);
      })
    },
    async initDashboard(){
      await this.initDates();
      await this.timeOut(1000);
      await this.initLedgers();
      await this.timeOut(1000);
      await this.prepareTapal();
      await this.timeOut(1000);
      this.initCircles();
    }
  }
};
</script>
